import React from 'react';
import PropTypes from 'prop-types';

function HomepageAnimation({ showVideo, handleVideoClick, handlerKeyDown }) {
  return (
    <div
      onKeyDown={handlerKeyDown}
      tabIndex="0"
      role="button"
      id="home-video-container"
      onClick={handleVideoClick}
    >
      {showVideo ? (
        <video key="water-drop-animation-init" autoPlay muted loop id="water-drop-animation-init">
          <source src="/videos/waterDropInit.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <>
          <video key="water-drop-animation" autoPlay muted id="water-drop-animation">
            <source src="/videos/waterDrop.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <audio autoPlay>
            <source src="/audio/ChirpsFromBlackHoles.mp3" type="audio/mp3" />
            <track kind="captions" srcLang="en" label="Empty Track" default />

            Your browser does not support the audio tag.
          </audio>
        </>
      )}
    </div>
  );
}

HomepageAnimation.propTypes = {
  showVideo: PropTypes.bool.isRequired,
  handleVideoClick: PropTypes.func.isRequired,
  handlerKeyDown: PropTypes.func.isRequired,
};

export default HomepageAnimation;
