import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import ProjectCard from '../components/ProjectCards';
import ProjectFilters from '../components/ProjectFilters';

import projectsData from '../data/projects.json';
import '../styles/Projects.css';

function Projects() {
  const [selectedType, setSelectedType] = useState('');
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const filteredProjects = projectsData.filter((project) => {
    if (selectedType && !project.type.includes(selectedType)) {
      return false;
    }

    if (selectedTechnologies.length > 0
      && !selectedTechnologies.every((tech) => project.technologies.includes(tech))) {
      return false;
    }

    return true;
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(true);
    }, 700);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Header />

      <main id="projects-main">
        <section id="projects-section">
          <div id="projects-quote-container">
            <p id="projects-quote">A confusão é o primeiro passo para o aprendizado.</p>
          </div>

          {isLoading && (
            <>
              <ProjectFilters
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                selectedTechnologies={selectedTechnologies}
                setSelectedTechnologies={setSelectedTechnologies}
              />

              <div id="projects-carousel">
                {filteredProjects.map((project) => (
                  <ProjectCard
                    key={project.id}
                    id={project.id}
                    name={project.name}
                    description={project.description}
                    image={project.image}
                    githubLink={project.github_link}
                    technologies={project.technologies}
                  />
                ))}
              </div>

              <span id="github-contact">
                <a href="https://github.com/rafittu" target="_blank" rel="noreferrer">
                  <img src="/images/github.png" id="github-icon" alt="author page github shortcut" />
                </a>
              </span>
            </>
          )}
        </section>
      </main>
    </>
  );
}

export default Projects;
