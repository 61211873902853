import React from 'react';
import { Link } from 'react-router-dom';

import logoImage from '../public/images/logo.png';
import headerImage from '../public/images/Header.png';

import '../styles/Header.css';

function Header() {
  return (
    <header>
      <img id="head-logo" src={logoImage} alt="Wophi logo" />
      <img id="head-title" src={headerImage} alt="Wophi" />
      <nav>
        <Link to="/" id="nav-home-btn">
          Inicio
        </Link>
        <Link to="/2061" id="nav-2061-btn">
          2061
        </Link>
        <Link to="/projects" id="nav-projects-btn">
          Projetos
        </Link>
        <Link to="/about" id="nav-wophi-btn">
          WoPhi
        </Link>
      </nav>
    </header>
  );
}

export default Header;
