import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import '../styles/Matrix.css';

function Matrix({ randomNumbers }) {
  const lastFourNumbers = randomNumbers.slice(-4);

  useEffect(() => {
    const DEFAULT_COLOR = '';
    const YELLOW = 'yellow';
    const BLUE = 'blue';
    const RED = 'red';
    const ORANGE = 'orange';
    const GREEN = 'green';

    const [prevPrevPrevNumber, prevPrevNumber, prevNumber, crrNumber] = lastFourNumbers;

    const setCellColor = (row, col, color) => {
      const cellId = `a${row},${col}`;
      const cell = document.getElementById(cellId);
      if (cell) cell.style.backgroundColor = color;
    };

    if (crrNumber > prevNumber) {
      setCellColor(1, 3, BLUE);
      setCellColor(2, 3, DEFAULT_COLOR);
      setCellColor(3, 3, DEFAULT_COLOR);
    } else if (crrNumber < prevNumber) {
      setCellColor(1, 3, DEFAULT_COLOR);
      setCellColor(2, 3, DEFAULT_COLOR);
      setCellColor(3, 3, RED);
    } else {
      setCellColor(1, 3, DEFAULT_COLOR);
      setCellColor(2, 3, YELLOW);
      setCellColor(3, 3, DEFAULT_COLOR);
    }

    if (prevNumber > prevPrevNumber) {
      setCellColor(1, 2, GREEN);
      setCellColor(2, 2, DEFAULT_COLOR);
      setCellColor(3, 2, DEFAULT_COLOR);
    } else if (prevNumber < prevPrevNumber) {
      setCellColor(1, 2, DEFAULT_COLOR);
      setCellColor(2, 2, DEFAULT_COLOR);
      setCellColor(3, 2, ORANGE);
    } else {
      setCellColor(1, 2, DEFAULT_COLOR);
      setCellColor(2, 2, YELLOW);
      setCellColor(3, 2, DEFAULT_COLOR);
    }

    if (prevPrevNumber > prevPrevPrevNumber) {
      setCellColor(1, 1, BLUE);
      setCellColor(2, 1, DEFAULT_COLOR);
      setCellColor(3, 1, DEFAULT_COLOR);
    } else if (prevPrevNumber < prevPrevPrevNumber) {
      setCellColor(1, 1, DEFAULT_COLOR);
      setCellColor(2, 1, DEFAULT_COLOR);
      setCellColor(3, 1, RED);
    } else {
      setCellColor(1, 1, DEFAULT_COLOR);
      setCellColor(2, 1, YELLOW);
      setCellColor(3, 1, DEFAULT_COLOR);
    }
  }, [lastFourNumbers]);

  return (
    <div id="matrix-border">
      <div id="inside-border">
        <span id="first-row">
          <p id="a1,1" />
          <p id="a1,2" />
          <p id="a1,3" />
        </span>

        <span id="second-row">
          <p id="a2,1" />
          <p id="a2,2" />
          <p id="a2,3" />
        </span>

        <span id="third-row">
          <p id="a3,1" />
          <p id="a3,2" />
          <p id="a3,3" />
        </span>
      </div>
    </div>
  );
}

Matrix.propTypes = {
  randomNumbers: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default Matrix;
