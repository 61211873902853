/* eslint-disable no-console */
import React, {
  useEffect, useRef, useState,
} from 'react';
import AWS from 'aws-sdk';
import awsConfig from '../aws/config/aws-config';

function HomepageStars() {
  const videoRefs = useRef([]);
  const [mediaItems, setMediaItems] = useState([]);
  const [positions, setPositions] = useState([]);
  const [activeStars, setActiveStars] = useState([]);
  const [activeMedia, setActiveMedia] = useState({});

  const renderMediaInterval = 168;

  const playVideo = (index) => {
    if (videoRefs.current[index] && videoRefs.current[index].paused) {
      const video = videoRefs.current[index];

      if (video.readyState >= 2) {
        const playPromise = video.play();

        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.error('Error to reproduce media:', error);
          });
        }
      } else {
        video.addEventListener('canplaythrough', () => {
          const playPromise = video.play();

          if (playPromise !== undefined) {
            playPromise.catch((error) => {
              console.error('Error to reproduce media:', error);
            });
          }
        }, { once: true });
      }
    }
  };

  const pauseAllVideos = () => {
    videoRefs.current.forEach((video) => {
      if (video) {
        video.pause();
      }
    });
  };

  const getRandomMedia = () => mediaItems[Math.floor(Math.random() * mediaItems.length)];

  const handleMouseEnter = (index) => {
    if (!activeMedia[index]) {
      setActiveMedia((prevMedia) => {
        const existingMedia = prevMedia[index];
        if (existingMedia) {
          return prevMedia;
        }

        const previousMedia = mediaItems[index];
        return { ...prevMedia, [index]: previousMedia || getRandomMedia() };
      });
    }

    playVideo(index);
    setActiveStars((prevActiveStars) => [...prevActiveStars, index]);
  };

  const handleMouseLeave = (index) => {
    pauseAllVideos();
    setActiveStars((prevActiveStars) => prevActiveStars.filter((starIndex) => starIndex !== index));
    setActiveMedia((prevMedia) => {
      const newMedia = { ...prevMedia };
      delete newMedia[index];
      return newMedia;
    });
  };

  const getRandomPosition = () => {
    const randomNumberInRange = (min, max) => Math.random() * (max - min) + min;

    return {
      top: `${randomNumberInRange(3, 94)}%`,
      left: `${randomNumberInRange(2, 97)}%`,
    };
  };

  useEffect(() => {
    const newPositions = mediaItems.map(() => getRandomPosition());
    setPositions(newPositions);
  }, [mediaItems]);

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: awsConfig.accessKeyId,
      secretAccessKey: awsConfig.secretAccessKey,
      region: awsConfig.region,
    });
    const S3_BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME || '';

    const convertS3ObjectToMedia = (item) => {
      const type = item.Key.endsWith('.mp4') ? 'video' : 'image';
      const src = `https://${S3_BUCKET_NAME}.s3.${awsConfig.region}.amazonaws.com/${item.Key}`;
      const alt = item.Key.split('/').pop().split('.')[0];

      return { type, src, alt };
    };

    const loadMediaFromS3 = async () => {
      try {
        const s3Objects = await s3.listObjectsV2({ Bucket: S3_BUCKET_NAME }).promise();
        const media = s3Objects.Contents.map((item) => convertS3ObjectToMedia(item));

        media.forEach((mediaItem, index) => {
          const timeout = setTimeout(() => {
            setMediaItems((prevMediaItems) => [...prevMediaItems, mediaItem]);
          }, index * renderMediaInterval);
          return () => clearTimeout(timeout);
        });
      } catch (error) {
        console.error('Error to get media from S3', error);
      }
    };

    loadMediaFromS3();
  }, []);

  return (
    <div className="about-container">
      {mediaItems.map((media, index) => (
        <div
          className={`about-media ${activeStars.includes(index) ? 'active' : ''}`}
          key={`media-item:${media.src}-${index + 1}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave(index)}
          style={{ ...positions[index] }}
        >
          {activeMedia[index] ? (
            <div>
              {activeMedia[index].type === 'image' ? (
                <img src={activeMedia[index].src} alt={activeMedia[index].alt} className="about-media-item" loading="lazy" />
              ) : (
                <video
                  ref={(videoRef) => {
                    videoRefs.current[index] = videoRef;
                  }}
                  autoPlay
                  controls
                  className="about-media-item"
                  loading="lazy"
                >
                  <source src={activeMedia[index].src} type="video/mp4" />
                  <track kind="captions" srcLang="en" label="Empty Track" default />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          ) : (
            <div className="about-media-item-placeholder" />
          )}
        </div>
      ))}
    </div>
  );
}

export default HomepageStars;
