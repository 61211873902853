import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const AVAILABLE_TYPES = {
  BACKEND: 'Back-end',
  FRONTEND: 'Front-end',
  FULLSTACK: 'Full stack',
};

const AVAILABLE_TECHNOLOGIES = {
  BCRYPT: 'Bcrypt',
  CSS: 'CSS',
  CRYPTO: 'Crypto',
  DOCKER: 'Docker',
  FLASK: 'Flask',
  HOOKS: 'React Hooks',
  JEST: 'Jest',
  JWT: 'JWT - Json Web Token',
  MACHINE_LEARNING: 'Machine Learning',
  NESTJS: 'Nest.js',
  NODEJS: 'Node.js',
  NUMPY: 'NumPy',
  POSTGRESQL: 'PostgreSQL',
  PRISMA_ORM: 'Prisma ORM',
  PROPTYPES: 'PropTypes',
  PYTHON: 'Python',
  REACTJS: 'React.js',
  REDIS: 'Redis',
  SCIKIT_LEARN: 'Scikit-learn',
  SQS: 'SQS',
  SWAGGER: 'Swagger',
  TYPESCRIPT: 'TypeScript',
};

function ProjectFilters({
  setSelectedType, selectedTechnologies, setSelectedTechnologies,
}) {
  const handleTypeChange = useCallback((event) => {
    setSelectedType(event.target.value);
  }, [setSelectedType]);

  const handleTechnologyChange = useCallback((event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedTechnologies([...selectedTechnologies, value]);
    } else {
      setSelectedTechnologies(selectedTechnologies.filter((tech) => tech !== value));
    }
  }, [selectedTechnologies, setSelectedTechnologies]);

  return (
    <div id="project-filters">
      <span id="type-filter">
        <label htmlFor="type-select">
          <select name="types" id="type-select" onChange={handleTypeChange}>
            <option value="">--Escolha uma stack--</option>
            {Object.entries(AVAILABLE_TYPES).map(([key, value]) => (
              <option key={key} value={key}>{value}</option>
            ))}
          </select>
        </label>
      </span>

      <span id="tech-filter">
        {Object.entries(AVAILABLE_TECHNOLOGIES).map(([key, value]) => (
          <label key={key} htmlFor={`technology-${key}`}>
            <input
              type="checkbox"
              id={`technology-${key}`}
              name="technologies"
              value={key}
              onChange={handleTechnologyChange}
            />
            {value}
          </label>
        ))}
      </span>
    </div>
  );
}

ProjectFilters.propTypes = {
  selectedTechnologies: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedTechnologies: PropTypes.func.isRequired,
  setSelectedType: PropTypes.func.isRequired,
};

export default ProjectFilters;
