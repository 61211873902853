import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Halley from '../pages/Halley';
import Home from '../pages/Home';
import Projects from '../pages/Projects';
import Wophi from '../pages/Wophi';
import ProjectPage from '../pages/ProjectPage';

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route exact path="/2061" element={<Halley />} />
      <Route exact path="/about" element={<Wophi />} />
      <Route exact path="/projects" element={<Projects />} />
      <Route path="/project/:projectId" element={<ProjectPage />} />
    </Routes>
  );
}

export default Router;
