import React from 'react';
import ProviderManager from './contexts/ProviderManager';

import './styles/App.css';

function App() {
  return <ProviderManager />;
}

export default App;
