import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import projectsData from '../data/projects.json';

import '../styles/ProjectPage.css';

function ProjectPage() {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProject = () => {
      const filteredProject = projectsData.find((index) => index.id === projectId);
      setProject(filteredProject);
      setIsLoading(false);
    };

    fetchProject();
  }, [projectId]);

  useEffect(() => {
    if (project && project.image) {
      const aboutProjectMain = document.getElementById('about-project-main');

      if (aboutProjectMain) {
        aboutProjectMain.style.position = 'relative';
        const styleElement = document.createElement('style');
        styleElement.innerHTML = `
          #about-project-main::before {
            content: '';
            background-image: url(${project.image});
            background-size: cover;
            background-position: center;
            opacity: 0.7;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
          }
        `;
        document.head.appendChild(styleElement);
      }
    }
  }, [project]);

  return (
    <>
      <Header />

      <main id="about-project-main">
        {!isLoading && (
        <>
          <h1 id="project-title">{project.title}</h1>

          <div id="project-content">
            <section id="about-project-section">
              <article>
                <p>
                  {project.about && project.about.includes('\n')
                    ? project.about.split('\n').map((line) => (
                      <span key={line}>
                        {line}
                        <br />
                      </span>
                    ))
                    : project.about}
                </p>
              </article>

              <aside>
                <h3>Techs</h3>

                <ul>
                  {project.technologies.map((technology) => (
                    <li key={technology}>{technology}</li>
                  ))}
                </ul>
              </aside>

              <div id="github-button-container">
                <a
                  href={project.github_link}
                  target="_blank"
                  rel="noreferrer"
                  id="github-button"
                >
                  <i className="fa-brands fa-github" />
                  {' '}
                  Ver no GitHub
                </a>
              </div>
            </section>
          </div>
        </>
        )}
      </main>
    </>

  );
}

export default ProjectPage;
