import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function ProjectCard({
  id, name, description, image, githubLink,
}) {
  const handleClickGitHub = () => {
    window.open(githubLink, '_blank');
  };

  return (
    <div className="project-card">
      <img src={image} alt={name} loading="lazy" />

      <div className="project-info">
        <h2>
          <Link id="project-page-link" className="unstyled-link" to={`/project/${id}`}>
            {name}
          </Link>
        </h2>

        <p>{description}</p>

        <button
          type="button"
          className="github-link-btn"
          onClick={handleClickGitHub}
        >
          GitHub Link
        </button>
      </div>
    </div>
  );
}

ProjectCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  githubLink: PropTypes.string.isRequired,
};

export default ProjectCard;
