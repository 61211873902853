import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import PropTypes from 'prop-types';

import '../styles/MatrixChart.css';

function MatrixChart({ randomNumbers }) {
  const chartRef = useRef(null);
  const reversedChartRef = useRef(null);

  const randomNumbersArr = randomNumbers.slice(-9);
  const reversedRandomNumbersArr = randomNumbers.slice(-299792458).reverse();

  const updateChart = (chart, data) => {
    const chartData = chart.data;
    chartData.labels = data.labels;
    chartData.datasets[0].data = data.datasets[0].data;
    chart.update();
  };

  useEffect(() => {
    const chartData = {
      labels: randomNumbersArr.map((_, index) => `#${index + 1}`),
      datasets: [{
        label: 'Random Numbers',
        data: randomNumbersArr,
        borderColor: 'rgb(189, 195, 199)',
        borderWidth: 1,
        tension: 0.5,
      }],
    };

    const reversedChartData = {
      labels: reversedRandomNumbersArr.map((_, index) => `#${index + 1}`),
      datasets: [{
        label: 'Random Numbers',
        data: reversedRandomNumbersArr,
        borderColor: 'rgb(189, 195, 199)',
        borderWidth: 1,
        tension: 0.5,
      }],
    };

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: { display: false },
        x: { display: false },
      },
      plugins: {
        legend: { display: false },
        title: { display: false },
      },
      layout: {
        padding: {
          top: 10, bottom: 10, left: 5, right: 5,
        },
      },
      elements: { point: { radius: 0 } },
    };

    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      if (chartRef.current.chart) {
        updateChart(chartRef.current.chart, chartData);
        updateChart(reversedChartRef.current.chart, reversedChartData);
      } else {
        chartRef.current.chart = new Chart(ctx, {
          type: 'line',
          data: chartData,
          options: chartOptions,
        });
      }
    }

    if (reversedChartRef.current) {
      const reversedCtx = reversedChartRef.current.getContext('2d');

      if (reversedChartRef.current.chart) {
        updateChart(reversedChartRef.current.chart, reversedChartData);
      } else {
        reversedChartRef.current.chart = new Chart(reversedCtx, {
          type: 'line',
          data: reversedChartData,
          options: chartOptions,
        });
      }
    }
  }, [randomNumbersArr, reversedRandomNumbersArr]);

  return (
    <>
      <div id="matrix-chart">
        <canvas ref={chartRef} />
      </div>

      <div id="mirror-matrix-chart">
        <canvas ref={reversedChartRef} />
      </div>
    </>
  );
}

MatrixChart.propTypes = {
  randomNumbers: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default MatrixChart;
