import React, { useEffect, useState } from 'react';
import Header from '../components/Header';

import '../styles/Home.css';
import HomepageAnimation from '../components/HomepageAnimation';
import HomepageStars from '../components/HomepageStars';

function Home() {
  const [showContent, setShowContent] = useState({
    animation: true,
    quote: false,
    about: false,
  });
  const [keyPosition, setKeyPosition] = useState();
  const [lockPosition, setLockPosition] = useState();

  const startAboutDelay = 47000;
  const quoteContents = [
    'O sentido da vida para Carlo Rovelli,',
    'é o que penso ser o sentido da vida para todos nós:',
    'a rica combinação de necessidades, desejos, aspirações, ambições,',
    'ideias, paixões, amor e entusiasmo, que surgem em várias medidas',
    'e em diferentes versões naturalmente de dentro de nós.',
    'A vida é uma explosão de significados!',
  ];

  const updateShowContent = (updates, delay = 0) => {
    setTimeout(() => {
      setShowContent((prevShowContent) => ({ ...prevShowContent, ...updates }));
    }, delay);

    if (updates.quote) {
      updateShowContent({ about: true }, startAboutDelay);
    }
  };

  const handleVideoClick = () => {
    updateShowContent({ animation: false });
    updateShowContent({ quote: true });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      updateShowContent({ animation: false });
      updateShowContent({ quote: true });
    }
  };

  const getRandomPosition = () => {
    const randomNumberInRange = (min, max) => Math.random() * (max - min) + min;

    return {
      top: `${randomNumberInRange(15, 94)}%`,
      left: `${randomNumberInRange(0, 94)}%`,
    };
  };

  useEffect(() => {
    setKeyPosition(getRandomPosition());
    setLockPosition(getRandomPosition());
  }, [showContent.about]);

  return (
    <>
      <Header />

      <main id="home-main">
        <HomepageAnimation
          showVideo={showContent.animation}
          handleVideoClick={handleVideoClick}
          handlerKeyDown={handleKeyDown}
        />

        {showContent.quote && (
          <section id="section-home-quote">
            <span id="home-quote">
              {quoteContents.map((content, index) => (
                <p key={`home-quote-p${index + 1}`} id={`home-quote-p${index + 1}`}>
                  {content}
                </p>
              ))}
            </span>
          </section>
        )}

        {showContent.about && (
          <section id="about">
            <img src="/images/key.png" style={{ ...keyPosition }} id="golden-key" alt="golden key to unlock the lock" />
            <img src="/images/lock.png" style={{ ...lockPosition }} id="golden-lock" alt="golden lock to be unlocked" />

            <span id="contact-info">
              <a href="https://www.linkedin.com/in/rafittu" target="_blank" rel="noreferrer">
                <img src="/images/linkedin.png" id="linkedin-icon" alt="author page linkedin shortcut" />
              </a>
            </span>

            <HomepageStars />
          </section>
        )}
      </main>
    </>
  );
}

export default Home;
