import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Matrix from '../components/Matrix';
import MatrixChart from '../components/MatrixChart';

import '../styles/Wophi.css';

function Wophi() {
  const [randomNumbers, setRandomNumbers] = useState([]);
  const [firstInteraction, setFirstInteraction] = useState(false);
  const [paragraphInterection, setParagraphInterection] = useState(
    [false, false, false, false, false],
  );

  const INTERVAL_TIME = 900;

  useEffect(() => {
    const interval = setInterval(() => {
      const randomNumber = Math.floor(Math.random() * 10);

      setRandomNumbers((prevNumbers) => {
        const updatedNumbers = [...prevNumbers, randomNumber];
        return updatedNumbers;
      });

      if (!firstInteraction) {
        setFirstInteraction(true);
      }
    }, INTERVAL_TIME);

    return () => clearInterval(interval);
  }, [firstInteraction]);

  const handleParagraphHover = (index) => {
    const updatedInteractions = [...paragraphInterection];
    updatedInteractions[index] = true;
    setParagraphInterection(updatedInteractions);
  };

  return (
    <>
      <Header />

      <main id="wophi-main">
        <Matrix
          randomNumbers={randomNumbers}
        />

        <MatrixChart
          randomNumbers={randomNumbers}
        />

        {firstInteraction && (
        <section id="wophi-section">
          <div id="wophi-quote">
            <p
              className={paragraphInterection[0] ? 'red-color' : ' '}
              onMouseEnter={() => handleParagraphHover(0)}
            >
              Eu acredito na intuição e na inspiração.
            </p>
            <p
              className={paragraphInterection[1] ? 'orange-color' : ' '}
              onMouseEnter={() => handleParagraphHover(1)}
            >
              A imaginação é mais importante que o conhecimento. O conhecimento é limitado,
            </p>
            <p
              className={paragraphInterection[2] ? 'green-color' : ' '}
              onMouseEnter={() => handleParagraphHover(2)}
            >
              enquanto a imaginação abraça o mundo inteiro, estimulando o progresso,
            </p>
            <p
              className={paragraphInterection[3] ? 'blue-color' : ' '}
              onMouseEnter={() => handleParagraphHover(3)}
            >
              dando
              {' '}
              <b>à luz à evolução</b>
              . Ela é, rigorosamente falando,
            </p>
            <p
              className={paragraphInterection[4] ? 'purple-color' : ' '}
              onMouseEnter={() => handleParagraphHover(4)}
            >
              um fator real na pesquisa científica.
            </p>
            <div id="Einsten">Albert Einstein</div>

            {paragraphInterection.every((value) => value) && (
              <span>
                <button disabled type="button" id="start-wophi-button">iniciar</button>
              </span>
            )}
          </div>
        </section>
        )}
      </main>
    </>
  );
}

export default Wophi;
